import { AxiosResponse } from 'axios'
import { CommerceLayerAddress, FormattedAddress } from '../../types'
import {
  CLObject,
  CLOrder,
  CLPaymentMethodListResponse,
  CLShippingMethodListResponse,
  OrderStatus,
  ResourceType,
} from './types'
import { DistributionCenter, FormattedAddressUpdate, Market } from '../../..'
import fetch, { headers } from './fetch'
import { ByHashZone } from '../../context/Location/context'

const formatAddress = (
  { id, attributes }: CommerceLayerAddress,
  customerAddresses?: CommerceLayerAddress[],
): FormattedAddress => ({
  id,
  marketId: attributes.metadata?.marketId,
  market: attributes.city,
  marketName: attributes.metadata?.marketName,
  zoneName: attributes.zip_code,
  zoneId: attributes.metadata?.zoneId,
  isFavorite: attributes.metadata?.favorite,
  shipping_address: attributes?.line_1,
  shipping_instructions: attributes.notes,
  name: attributes.full_name,
  shipping_phone: attributes.phone,
  shipping_name: attributes.metadata?.alias,
  shipping_number: attributes.line_2,
  customer_address: customerAddresses?.filter((address) => address.relationships?.address.data.id === id)[0].id,
  geocode: {
    latitude: attributes.lat,
    longitude: attributes.lng,
  },
  distributionCenterId: attributes.metadata?.distributionCenterId,
})

const getFavoriteAddressId = (addresses: FormattedAddress[]): string | undefined => {
  let result

  addresses.forEach((address) => {
    if (address.isFavorite) result = address.id
  })

  return result
}

const validateToBo = (address: FormattedAddress) =>
  address?.zoneName === 'La Paz' || address?.zoneName === 'El Alto' ? 'La Paz - El Alto' : address?.zoneName

const sanitizeAddress = (addresses: FormattedAddress[], byHashZones: ByHashZone): FormattedAddressUpdate[] => {
  return addresses.map((address) => {
    return {
      ...address,
      market: byHashZones[validateToBo(address)]?.market,
      marketId: parseInt(byHashZones[validateToBo(address)]?.marketId, 10),
      marketName: byHashZones[validateToBo(address)]?.marketName,
      zoneId: byHashZones[validateToBo(address)]?.id,
      zoneName: byHashZones[validateToBo(address)]?.name,
      distributionCenterId: byHashZones[validateToBo(address)]?.distributionCenterId,
    }
  })
}
const getAvailableAddresses = (currentMarket: number, addresses: FormattedAddress[]): [FormattedAddress[], boolean] => {
  const valid = addresses.filter(({ marketId }) => marketId === currentMarket)
  const hasAvailable = addresses.length !== 0 ? valid.length !== 0 : false

  return [valid, hasAvailable]
}

export const getAvailableAddressesToUpdate = (
  addresses: FormattedAddress[],
  byHashZones: ByHashZone,
): FormattedAddressUpdate[] => {
  const invalidAddresses = addresses.filter(({ distributionCenterId }) => !distributionCenterId)
  return sanitizeAddress(invalidAddresses, byHashZones)
}

const sort = (addresses: FormattedAddress[]): FormattedAddress[] =>
  addresses.sort((a) => {
    if (a.isFavorite) return -1

    return 1
  })

export { formatAddress, getFavoriteAddressId, getAvailableAddresses, sort }

export type IncludedDicts = Record<ResourceType, Record<string, CLObject>>

export function buildIncludedDictionaries(included: CLObject[] | undefined): IncludedDicts {
  const includedDicts: IncludedDicts = Object.values(ResourceType).reduce(
    (dict, type) => ({ ...dict, [type]: {} }),
    {} as IncludedDicts,
  )
  if (included) {
    included.forEach((resource) => {
      includedDicts[resource.type][resource.id] = resource
    })
  }
  return includedDicts
}

export const addShippingAndPaymentMethod = async (city: Market, distributionCenter: DistributionCenter) => {
  return {
    ...city,
    commerceLayer: {
      ...distributionCenter.commerceLayer,
    },
  }
}

const getOrderDateByStatus = (order: CLOrder): string => {
  switch (order.attributes.status) {
    case OrderStatus.APPROVED:
      return order.attributes.approved_at

    case OrderStatus.PLACED:
      return order.attributes.placed_at

    default:
      return order.attributes.updated_at
  }
}

export const filterOrders = (orders: CLOrder[], maxAge: number): CLOrder[] => {
  const filterStatus = [OrderStatus.APPROVED, OrderStatus.PLACED, OrderStatus.CANCELLED]

  const validOrders = orders
    .filter((order) => {
      if (filterStatus.includes(order.attributes.status)) {
        const orderDate = getOrderDateByStatus(order)
        const now = new Date().getTime()
        const orderDateTime = new Date(orderDate).getTime()
        const orderDaysOfDifference = (now - orderDateTime) / (1000 * 3600 * 24)

        return orderDaysOfDifference <= maxAge
      }

      return false
    })
    .sort((a, b) => {
      return new Date(b.attributes.updated_at).getTime() - new Date(a.attributes.updated_at).getTime()
    })

  return validOrders
}
